@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titan+One&display=swap");
html,
body,
span,
div,
a,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  color: #1f2024;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
.bg-orange {
  background-color: #efa23a !important;
}

.bg-dark {
  background-color: #1f2024 !important;
}

.btn-orange {
  background-color: #efa23a !important;
  color: #fff !important;
}
.search-input-bg {
  background-color: #c5e7e8;
}

.btn {
  border-radius: 10px !important;
}

.btn svg {
  width: 24px;
  height: 24px;
  color: #fff;
  margin-left: 10px;
}

.max-width-table {
  max-width: 90%; /* Set your desired maximum width here */
  margin: 0 auto; /* Center the table horizontally (optional) */
}

.table td {
  padding: 30px 0 30px 15px !important;
}

.table svg {
  width: 24px;
  height: 24px;
  color: #c2c8d6;
}

select {
  color: #000 !important;
  background-color: #fff !important;
}

select {
  color: #000 !important;
  background-color: #fff !important;
}

.table .tr-table {
  background-color: #fff;
  border-bottom-width: 5px !important;
  border-radius: 5px !important;
}
.table .td-table {
  padding: 7px !important;
}
.table .td-text {
  max-width: 150px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-border {
  border-radius: 12px;
  border: solid 1px #c2c8d6;
  padding: 10px;
}

.text-yellow {
  color: #f2c94c;
}
.text-orange {
  color: #f2994a;
}
.text-black {
  color: #15171d;
}
.text-blue {
  color: #5cc6e6;
}

.nav-link svg {
  width: 24px;
  height: 24px;
  color: #fff;
}

.nav-item svg {
  width: 24px;
  height: 24px;
  color: #fff !important;
}

.menu-title {
  color: #fff !important;
}

.navbar-brand svg {
  width: 24px;
  height: 24px;
}

.border-nav-item {
  border-radius: 10px;
  border: solid 2px #fff;
}

.btnSvg svg {
  width: 24px;
  height: 24px;
}

.nav-item:hover {
  background-color: #efa23a !important;
  color: #fff;
}

.navbar .navbar-brand-wrapper .navbar-brand {
  color: #1f2024 !important;
}

.full-screen-container {
  height: 100vh;
}

.remove-padding > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.remove-margin > * {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/**custom **/

.content-wrapper.wrap {
  padding: 20px 24px;
}

.wrap .card .card-body {
  padding: 24px;
}

.wrap .row {
  margin-inline: -4px;
  & > [class*="col-"] {
    padding-inline: 4px;
  }
}

.wrap {
  & .form-control:not(textarea),
  & .form-select {
    padding: 14px 16px;
    height: 44px;
    border-radius: 12px;
  }

  /* & span{
    display: inline-block;
  } */

  & .radio-custom {
    & .form-radio {
      height: 44px;
      margin: 0 !important;
      border: none;
      position: relative;

      & > input {
        border-color: #9ca3af;
        margin-right: 4px;
        background-image: none;
      }

      & > input:checked {
        background-color: #0284fe;
        width: 14px;
        height: 14px;
        outline: 1px solid #0284fe;
        outline-offset: 1px;
        box-shadow: none;
      }
      & > label {
        color: #6b7280;
      }
      & > input:checked ~ label {
        color: #000;
      }
      & > input:checked ~ label:after {
        content: "";
        position: absolute;
        inset: 0;
        outline: 1px solid #0284fe;
        border-radius: 12px;
      }
    }

    & > label {
      height: 15px;
    }
  }

  .custom-input-file {
    display: flex;
    align-items: center;
    max-width: 100%;
    border-radius: 12px;
    padding: 14px 16px;

    & > label {
      display: none;
    }
    & button {
      margin-left: auto;
    }
  }
}

@media (max-width: 1300px) and (min-width: 992px) {
  .row .col-xx {
    width: 100%;
    flex: 0 0 auto;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  & .wrap,
  .card-body {
    padding-inline: 15px !important;
  }
  & .radio-custom {
    & > label:not(:first-child) {
      height: 0;
    }
  }
}

/** Profile **/

.profile-header {
  color: #1f2024;
  font-family: "Inter";

  & .card-body {
    max-width: 860px;
    padding: 40px;
    display: flex;
    gap: 40px;

    & img {
      border-radius: 10px;
      object-fit: cover;
    }
  }
  & .porfile-header-info {
    max-width: 100%;
  }
  & .porfile-header-info > *:not(:first-child) {
    margin-block: 6px;
  }

  & .profile-number {
    color: #71727a;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
  }
  & .profile-name {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 111.111% */
    /* text-transform: uppercase; */
  }
  & .badge-orange {
    font-family: "Inter";
    color: #efa23a;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    border-radius: 50px;
    border: 1px solid #efa23a;
  }
}

/** tabs **/
.tabs-custom {
  border-radius: 16px;
  background: #fef3e9;
  & .nav-item {
    border-radius: 60px !important;
    overflow: hidden;
  }
  & .nav-link {
    color: #71727a;
    text-align: center;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &.active {
      background-color: #efa23a !important;
      color: #15171d !important;
    }
  }
}

select.form-control {
  padding: 0.94rem 1.375rem !important;
  color: #000 !important;
  background-color: #fff !important;
}

/** Dashboard **/

.image_card {
  width: 64px !important;
  height: 64px !important;
  margin: 0 auto;
  margin-top: 15px;
  object-fit: cover; /* Assure que l'image couvre l'espace attribué sans déformer l'aspect */
}
.title_card {
  /* margin-top: -37%; */
  text-align: center;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.bar_graph {
  /* margin-top: 3%; */
}
.container_bar {
  background-color: "#f0f0f0";
}
.row_graph {
  /* background-color: white;
  margin-top: 1%; */
}
.text_card {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #71727a;
}

.card-percentage {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dashboard-card {
  border-radius: 16px;

  & .form-select {
    /* width: 20%;
    float: right;
    margin-right: -168%;
    margin-top: 3%; */
    height: auto !important;
    width: 100%;
  }
}

.dot-list {
  & li {
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    display: flex;
    align-items: center;
    gap: 4px;
    padding-block: 8px;

    &:before {
      content: "";
      width: 4px;
      height: 4px;
      background-color: #efa23a;
      display: block;
      border-radius: 50%;
    }
  }
}

.filter-card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  background: #fef3e9;
  border-radius: 12px;
  margin-block: 24px;
  padding: 16px;
  width: max-content;
  max-width: 100%;

  & > * {
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  & > *.active {
    background-color: #efa23a !important;
    border-color: #efa23a !important;
  }
  & .form-select {
    height: auto !important;
    max-width: max-content;
    padding-right: 32px !important;
  }
  & .all-province {
    border-color: #71727a;
  }
}

.list-flex {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & li {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    border-radius: 8px !important;
    padding: 8px 16px;

    & span:first-child {
      color: #efa23a;
    }
  }
}

.add-member {
  & .filter-card {
    gap: 8px;
  }
}

.navbar .navbar-brand-wrapper,
.navbar .navbar-menu-wrapper {
  height: auto !important;
}

.navbar.fixed-top + .page-body-wrapper {
  background-color: #f2edf3;
}

.navbar.fixed-top + .page-body-wrapper {
  padding-top: 100px !important;
}
@media (min-width: 991px) {
  .navbar.fixed-top + .page-body-wrapper {
    padding-top: 45px !important;
  }
}

.dashboard-card {
  & .img-people {
    width: 72px;
    height: 72px;
  }
  & .title-people {
    font-family: "Inter" !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.canvas-container canvas {
  max-width: 120px;
  max-height: 120px;
}

/**Event **/

.event-wrapper,
.reward-wrapper {
  & .btn-add-event {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 12px;
    background-color: #efa23a;
    color: #fff;

    & span {
      color: inherit !important;
      line-height: 0;
    }
  }

  & .input-search {
    border-radius: 60px;
    overflow: hidden;
    background-color: #f8f9fe;
    padding-block: 4px;

    & input {
      background-color: transparent;
      border: 0;
    }
    & .input-group-text {
      background-color: transparent;
      border: 0;
      padding-left: 20px;
      opacity: 1;
    }
  }

  & .event-filter {
    & .filter-card {
      padding: 4px;
    }
    & select {
      font-family: "Inter";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.table-custom {
  & td {
    padding: 0 !important;

    & > span {
      padding: 24px 16px;
      display: flex;
      background-color: #fff;
      margin-bottom: 16px;
      min-height: 62px;
      align-items: stretch;
    }
    &:first-child > span {
      border-radius: 12px 0 0 12px;
    }

    &:last-child > span {
      border-radius: 0 12px 12px 0;
      padding-block: 10px;
      justify-content: flex-end;
    }
  }

  & .check-list {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px !important;
    color: #fff;
    gap: 2px;
    background-color: #efa23a;
  }
}
.list-flush {
  & li {
    padding-block: 16px;
    border-style: dotted;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    & div:first-child > span:first-child {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
      color: #71727a;
    }
    & div:last-child {
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}
.justify_center {
  justify-content: center !important;
  text-align: center !important;
}
.body-modal {
  height: 305px;
  overflow: auto;
}

.min-height-220 {
  min-height: 221px !important;
  height: 100% !important;
}

.p-qr-code {
  position: absolute;
  bottom: 16px;
  right: 16px;
  & canvas {
    max-width: 90px;
    max-height: 90px;
  }
}

.card-profile {
  max-width: 400px;
  margin-inline: auto;
  min-height: 200px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid gray;
  width: 100%;
  position: relative;

  & img {
    border-radius: 0 !important;
  }
}

/**/

select.select-100 {
  max-width: 100% !important;
}
.form-check-input[type="checkbox"] {
  border-radius: 50% !important;
}

.btn-cdm-outline {
  color: #fff !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  border-radius: 12px !important;
  border: 1.5px solid #71727a !important;
  padding: 16px 32px !important;
  color: #71727a !important;
}
.btn-cdm-primary {
  color: #fff !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  border-radius: 12px !important;
  border: 1.5px solid #efa23a !important;
  padding: 16px 32px !important;
  color: #fff !important;
  background-color: #efa23a !important;
}

/** Stickerise **/

.stickerise {
  /* font-family: "Titan One", cursive; */
  /* display:none; */
  font-size: 18px;
  isolation: isolate;
  color: red;
  position: relative;
  /* -webkit-text-stroke: 0.01em #333; */
  white-space: nowrap;
  margin-right: 12px !important;
}

.stickerise.show {
  display: block;
}

.stickerise[data-sticker-text]::before {
  content: attr(data-sticker-text);
  z-index: -1;
  color: #fff;
  -webkit-text-stroke: 0.25em #fff;
  position: absolute;
  white-space: nowrap;
  inset: 0;
}

.stickerise[data-sticker-text]::after {
  content: attr(data-sticker-text);
  position: absolute;
  z-index: -2;
  color: #fff;
  -webkit-text-stroke: 0.5em rgba(0, 0, 0, 0.4);
  inset: 0.0125em 0 0 0.0125em;
  white-space: nowrap;
  filter: blur(0.0025em);
}

/**profile**/
#fill-tab-example-tabpane-profil {
  & [class*="col-"].col-custom {
    width: 100%;
  }
}

@media (min-width: 768px) {
  #fill-tab-example-tabpane-profil {
    & [class*="col-"].col-custom {
      width: 25%;
    }
  }
}
@media (min-width: 992px) {
  #fill-tab-example-tabpane-profil {
    & [class*="col-"].col-custom {
      width: 20%;
      min-width: 160px;
    }
  }
}

@media (max-width: 1300px) {
  #fill-tab-example-tabpane-profil {
    & [class*="col-"].is_in_list + [class*="col"]:not(.radio-custom) {
      width: 100%;
    }
  }
}
@media (min-width: 1300px) {
  [for="is_candidat_Non"] + div {
    width: 33.3333% !important;
  }
}

/**end **/

.svg-user {
  top: 50%;
  transform: translateY(-50%);
}

/**role selection**/
.role-selection {
  height: auto !important;

  max-width: max-content;
  padding-right: 40px !important;
}

/* styles/Loader.css */
.loader {
  position: absolute;
  top: 20%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/** partisan create div **/
.div-partisan {
  margin-left: 10px !important;
  margin-top: -10px !important;
}

.custom-z-index {
  z-index: 100;
}

/** member image **/

.table .td-img-member > div {
  width: 32px !important;
  & img {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
}

/** title stickerise **/

.title_stickerise {
  & .stickerise.show {
    display: block;
  }

  & .stickerise[data-sticker-text]::after {
    -webkit-text-stroke: 0.25em rgba(0, 0, 0, 1);
  }
}

.stickerise {
  &.green {
    color: green;
  }
  &.black {
    color: #000;
  }
  &.yellow {
    color: rgb(255, 187, 0);
  }
}

.stickerise_bottom{
  & .stickerise[data-sticker-text]::before {
    -webkit-text-stroke: 0.2em #fff;
  }

  & .stickerise[data-sticker-text]::after {
    -webkit-text-stroke: 0.25em rgba(0, 0, 0, 1);
  }
}

.green {
  color: green;
  font-size: 18px;
}

.black {
  color: #000;
  font-size: 18px;
}

.red {
  color: red;
  font-size: 18px;
}

.grey {
  color: grey;
  font-size: 18px;
}

/** custom calendar on chrome **/

  .custom-calendar {
    position:relative;
  }

  .custom-calendar::-webkit-calendar-picker-indicator {
      position:absolute;
      width:24px;
      height:24px;
      right:12px;
      top:50%;
      transform:translateY(-50%);
      text-align:center;
  }