@tailwind base;
@tailwind components;
@tailwind utilities;

.background-image {
width: 100vw; /* Largeur égale à la largeur de l'écran */
  height: 100vh;
  background-image: url('../src/assets/images/fond-login.jpg'); /* Chemin vers votre image */
  background-size: cover; /* Ajuster la taille de l'image pour couvrir toute la div */
  background-position: center;
}
.vh-75 {
    height: 70vh;
}
.vh-60 {
    height: 60vh;
}
.vw-75 {
    width: 75%;
}
.vw-95 {
    width: 95%;
}
.img-login {
    width: 100%;

    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    position: absolute
    /* margin-left: -2%; */
}
.preview {
    height: 100px;
    width: 100px !important;
}
.errors {
    color: red;
}
.loader-overlay {
    position: fixed;
  top: 25%; /* Adjust the top position to center vertically */
  left: 25%; /* Adjust the left position to center horizontally */
  width: 50%; /* Set the width to cover 50% of the viewport */
  height: 50%; /* Set the height to cover 50% of the viewport */
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the loader is above other elements */
}
.login-container {
    background-color: rgb(255, 255, 255, 0.4) !important;
}
.header-container {
    background-color: rgb(6, 141, 156);
}
.title-detail {
    font-size: 55px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    text-shadow: 2px 2px 6px rgba(255, 255, 255, 0.9)
}
.title-image {
    margin-top: 10%;
    width: 100px !important;
    height: 100px !important;
}
.title-description {
    text-shadow: 2px 2px 6px rgba(255, 255, 255, 0.9)
}
.form-login {
    width: 100%;
    margin-top: 20%;
}
.form-login input {
   height: 35px;
   border-radius: 8px;
   border: 1px solid #C5C6CC;
   padding: 12px 16px 12px 16px;
   gap: 8px;
   width: 100% !important;
}
.label-login {
    font-size: 14px;
    color: rgb(195, 234, 234);
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    width: 200px;
    text-shadow: 1px 1px 6px rgba(255, 255, 255, 0.9)
}
.btn-login {
    width: 180px !important;
    height: 40px;
    padding: 13px !important;
    border-radius: 8px !important;
    border: none;
    gap: 8px;
    display: flex !important;
    justify-content: center;
    justify-items: center;
    background: #51717c !important;
    margin-top: 2% !important;
    font-size: 14px !important;
    outline: none;
}

.btn-login:focus,
.btn-login:active {
  outline: none !important;
  outline-color: transparent !important;
  outline-style: none !important;
}

.login-label {
    color: rgb(240, 250, 250);
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.9)
}

.separator-white {
    border-top: 2px solid white;
    width: 75%;
}
.separator-black {
    border-top: 2px solid rgb(242, 242, 242);
    width: 95%;
    margin: auto;
}
.btn-login img {
    margin-top: 0px;
}

.audioToTextLabel {
    color: rgb(13, 124, 124);
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(190, 207, 207, 0.9)
}
.viewAllElement {
    color: rgb(13, 124, 124);
}
.transValide {
    background-color: rgb(13, 124, 124);
    margin-right: 40px;
}
.textToTranscribe {
    width: 50%;
    margin:auto
}

.viewLinkContainer:hover {
    background-color: rgb(204, 215, 215);

}
.colorSelected {
    color: rgb(9, 67, 67);
}

.textareaContainerStyle {
    width: 95%;
    border: 1px solid #ccc;
    margin: auto;
    padding: 10px;
    border-radius: 10px 10px 10px 10px;
  };

.textareaStyle {
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: #EFA23A;
    background-color: #51717c;
  }

.waveformStyle {
    width: 90%;
    margin : auto
}
.btnControl:hover {
    width: 35px;
    height: 35px;
}

.language-menu {
    position : absolute;
    background-color:#969088;
    opacity: 0.7;
    z-index: 10;
}
.languageChoice:hover {
    color: white;
    font-size: 16px;
}

.div_container_scrollable {
    height: 75vh;
  /* Masquer la barre de défilement */
    overflow: hidden;
    overflow-y: scroll;
    /* Pour masquer l'ascenseur spécifique à WebKit (Chrome, Safari) */
    scrollbar-width: none; /* Firefox ne prend pas en charge cette propriété */
    -ms-overflow-style: none;

}